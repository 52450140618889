.newwordcard
{
    display: flex;
    flex-direction: column;

    width: 40%;
    min-width: 20rem;
    max-width: 30rem;
    height: auto;
    max-height: 40rem;
    margin: 1rem auto;
    padding: 1.5rem;

    transition: max-width .4s ease-out,
    max-height 1s ease;

    border-radius: .4rem;
    background-color: #e11a27;
}

.newwordcard--collapsed
{
    width: auto;
    min-width: 0;
    max-width: 20rem;
    max-height: 3rem;
    padding: 0rem;

    border-radius: 3rem;
}

.newwordcard__primary,
.newwordcard__secondary
{
    display: flex;
    overflow: hidden;
}

.newwordcard--collapsed .newwordcard__primary,
.newwordcard--collapsed .newwordcard__secondary
{
    height: 0;
    margin: 0;
    padding: 0;

    border-width: 0;
}

.newwordcard__primary_icon,
.newwordcard__secondary_icon
{
    display: block;

    width: 1.6rem;
    height: 1rem;
    height: 1rem;
    margin: .8rem 1rem .8rem 0;

    content: '';

    background-repeat: no-repeat;
    background-position: left;
}

.newwordcard__primary_icon
{
    box-sizing: initial;

    border: .15rem solid transparent;
    background-image: url('/flags/de.svg');
}

.newwordcard__secondary_icon
{
    box-sizing: initial;

    border: .15rem solid white;
    background-image: url('/flags/ch.svg');
}

.newwordcard__primary_input,
.newwordcard__secondary_input
{
    flex-grow: 1;

    margin: .5rem 0;
    padding: .3rem .4rem;
}

.newwordcard__description_input
{
    margin: .5rem 0;
    margin-bottom: 1rem;
}

.newwordcard--collapsed .newwordcard__description_input
{
    overflow: hidden;

    height: 0;
    margin: 0;
    padding: 0;

    border-width: 0;
}

.newwordcard__add
{
    font-size: 1.3rem;
    line-height: 2.2rem;

    display: inline-block;
    align-self: flex-end;
    flex-grow: 0;

    height: 2.5rem;
    padding: 0 2rem;

    cursor: pointer;

    color: white;
    border: 3px solid white;
    border-radius: 1.5rem;
    background-color: transparent;
}

.newwordcard--collapsed .newwordcard__add
{
    align-self: center;

    width: auto;

    border: 0 solid transparent;
}
