.translationcard {
  display: block;
  border: 6px solid #E1E1E1;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  color: rgb(97, 97, 97);
  padding: 0.4rem 1.5rem;
  border-radius: 0.4rem;
  max-width: 30rem;
  min-width: 20rem;
  width: 40%;
  margin: 1rem auto;
}

.translationcard__primary,
.translationcard__secondary{
  margin: 0.5rem 0;
  font-weight: 600;
  font-size: 1.4rem;
}


.translationcard__secondary::before,
.translationcard__primary::before {
  content: "";
  background-repeat: no-repeat;
  background-position: left;
  width: 2.5rem;
  height: 1rem;
  display: inline-block;
}

.translationcard__primary::before {
  background-image: url("/flags/de.svg");
}

.translationcard__secondary::before {
  background-image: url("/flags/ch.svg");
}

.translationcard__description{
  margin: 1rem 0;
}
