.searchbar
{
    display: flex;
    align-items: stretch;

    min-width: 20rem;
    max-width: 30rem;
    margin: auto;
    padding: 1rem 2rem;

    border-radius: 1rem;
    background-color: #e11a27;
}
.searchbar.searchbar--header
{
    position: fixed;

    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 1.5rem 2rem;

    border-radius: 0 0 1rem 1rem;
}

.searchbar__title
{
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;

    flex: 1;

    padding: 0rem 1rem;

    color: white;
}

.searchbar__input
{
    font-size: 1.2rem;

    flex: 1;

    padding: .5rem 1rem;

    color: #333;
    border: none;
}

.searchbar.searchbar--header .searchbar__input
{
    font-size: 1rem;

    flex: 0;

    min-width: 15rem;
}

.searchbar__submit
{
    flex-shrink: 0;

    width: 2.2rem;
    height: 2.2rem;
    margin-left: 2rem;

    cursor: pointer;

    border: 0 solid #e11a27;
    background-color: #e11a27;
    background-image: url(/flags/ch.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.searchbar.searchbar--header .searchbar__submit
{
    margin-left: 1rem;
}
