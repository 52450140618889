@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet');

*
{
    font-family: 'Roboto', sans-serif;

    box-sizing: border-box;
}

html,
body,
#root,
.App
{
    overflow-x: hidden;
    align-items: center;
    justify-content: center;

    width: 100vw;
    min-width: 100vw;
    height: 100vh;
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

.App
{
    display: flex;
    flex-direction: column;
}

code
{
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
