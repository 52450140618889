.searchresultwindow
{
    min-width: 100%;
    min-height: 100%;
}

.searchresultwindow__results
{
    display: flex;
    align-items: center;
    flex-direction: column;

    padding-top: 6rem;
}
