.button{
  background-color: #E11A27;
  border : 0 none transparent;
  border-radius: 1.5rem;
  height: 3rem;
  color: white;
  line-height: 3rem;
  font-size: 1.3rem;
  padding: 0 2rem;
  cursor: pointer;
}