@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet);
.searchbar
{
    display: flex;
    align-items: stretch;

    min-width: 20rem;
    max-width: 30rem;
    margin: auto;
    padding: 1rem 2rem;

    border-radius: 1rem;
    background-color: #e11a27;
}
.searchbar.searchbar--header
{
    position: fixed;

    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 1.5rem 2rem;

    border-radius: 0 0 1rem 1rem;
}

.searchbar__title
{
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;

    flex: 1 1;

    padding: 0rem 1rem;

    color: white;
}

.searchbar__input
{
    font-size: 1.2rem;

    flex: 1 1;

    padding: .5rem 1rem;

    color: #333;
    border: none;
}

.searchbar.searchbar--header .searchbar__input
{
    font-size: 1rem;

    flex: 0 1;

    min-width: 15rem;
}

.searchbar__submit
{
    flex-shrink: 0;

    width: 2.2rem;
    height: 2.2rem;
    margin-left: 2rem;

    cursor: pointer;

    border: 0 solid #e11a27;
    background-color: #e11a27;
    background-image: url(/flags/ch.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.searchbar.searchbar--header .searchbar__submit
{
    margin-left: 1rem;
}

.chip{
  border: 6px solid #E1E1E1;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  color: rgb(97, 97, 97);
  padding: 0.4rem 1.5rem;
  border-radius: 0.4rem;
}
.chiplist{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.chiplist .chip{
  margin: 0.5rem 0.5rem;
}
.translationcard {
  display: block;
  border: 6px solid #E1E1E1;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  color: rgb(97, 97, 97);
  padding: 0.4rem 1.5rem;
  border-radius: 0.4rem;
  max-width: 30rem;
  min-width: 20rem;
  width: 40%;
  margin: 1rem auto;
}

.translationcard__primary,
.translationcard__secondary{
  margin: 0.5rem 0;
  font-weight: 600;
  font-size: 1.4rem;
}


.translationcard__secondary::before,
.translationcard__primary::before {
  content: "";
  background-repeat: no-repeat;
  background-position: left;
  width: 2.5rem;
  height: 1rem;
  display: inline-block;
}

.translationcard__primary::before {
  background-image: url("/flags/de.svg");
}

.translationcard__secondary::before {
  background-image: url("/flags/ch.svg");
}

.translationcard__description{
  margin: 1rem 0;
}

.newwordcard
{
    display: flex;
    flex-direction: column;

    width: 40%;
    min-width: 20rem;
    max-width: 30rem;
    height: auto;
    max-height: 40rem;
    margin: 1rem auto;
    padding: 1.5rem;

    -webkit-transition: max-width .4s ease-out,
    max-height 1s ease;

    transition: max-width .4s ease-out,
    max-height 1s ease;

    border-radius: .4rem;
    background-color: #e11a27;
}

.newwordcard--collapsed
{
    width: auto;
    min-width: 0;
    max-width: 20rem;
    max-height: 3rem;
    padding: 0rem;

    border-radius: 3rem;
}

.newwordcard__primary,
.newwordcard__secondary
{
    display: flex;
    overflow: hidden;
}

.newwordcard--collapsed .newwordcard__primary,
.newwordcard--collapsed .newwordcard__secondary
{
    height: 0;
    margin: 0;
    padding: 0;

    border-width: 0;
}

.newwordcard__primary_icon,
.newwordcard__secondary_icon
{
    display: block;

    width: 1.6rem;
    height: 1rem;
    height: 1rem;
    margin: .8rem 1rem .8rem 0;

    content: '';

    background-repeat: no-repeat;
    background-position: left;
}

.newwordcard__primary_icon
{
    box-sizing: content-box;
    box-sizing: initial;

    border: .15rem solid transparent;
    background-image: url('/flags/de.svg');
}

.newwordcard__secondary_icon
{
    box-sizing: content-box;
    box-sizing: initial;

    border: .15rem solid white;
    background-image: url('/flags/ch.svg');
}

.newwordcard__primary_input,
.newwordcard__secondary_input
{
    flex-grow: 1;

    margin: .5rem 0;
    padding: .3rem .4rem;
}

.newwordcard__description_input
{
    margin: .5rem 0;
    margin-bottom: 1rem;
}

.newwordcard--collapsed .newwordcard__description_input
{
    overflow: hidden;

    height: 0;
    margin: 0;
    padding: 0;

    border-width: 0;
}

.newwordcard__add
{
    font-size: 1.3rem;
    line-height: 2.2rem;

    display: inline-block;
    align-self: flex-end;
    flex-grow: 0;

    height: 2.5rem;
    padding: 0 2rem;

    cursor: pointer;

    color: white;
    border: 3px solid white;
    border-radius: 1.5rem;
    background-color: transparent;
}

.newwordcard--collapsed .newwordcard__add
{
    align-self: center;

    width: auto;

    border: 0 solid transparent;
}

.button{
  background-color: #E11A27;
  border : 0 none transparent;
  border-radius: 1.5rem;
  height: 3rem;
  color: white;
  line-height: 3rem;
  font-size: 1.3rem;
  padding: 0 2rem;
  cursor: pointer;
}

.searchwindow .chiplist{
  display: block;
  margin: 3rem auto 0;
  max-width: 30rem;
}
.searchresultwindow
{
    min-width: 100%;
    min-height: 100%;
}

.searchresultwindow__results
{
    display: flex;
    align-items: center;
    flex-direction: column;

    padding-top: 6rem;
}

.App {
  font-family: "Roboto", sans-serif;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

*
{
    font-family: 'Roboto', sans-serif;

    box-sizing: border-box;
}

html,
body,
#root,
.App
{
    overflow-x: hidden;
    align-items: center;
    justify-content: center;

    width: 100vw;
    min-width: 100vw;
    height: 100vh;
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

.App
{
    display: flex;
    flex-direction: column;
}

code
{
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

